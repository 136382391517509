import React from 'react';
import gql from 'graphql-tag';

export const defaultValue = {
  loading: false,
  loaded: false,
};

export const getDefaultValue = () => ({
  ...defaultValue,
});

const IntegrationsContext = React.createContext(defaultValue);

IntegrationsContext.displayName = 'IntegrationsContext';

IntegrationsContext.fragments = {
  integration: gql`
    fragment IntegrationsContextIntegration on Integration {
      alert {
        active
        context
        message
      }
      name
    }
  `,
};

export default IntegrationsContext;
