
function category(categoryName, items) {
  return items.map(item => ({
    ...item,
    category: categoryName,
  }));
}

function define(name, size) {
  return { name, size };
}

function defineSize(id, volume, description) {
  return { id, volume, description };
}

export const categories = {
  bedroom: 'Bedroom',
  kitchen: 'Kitchen',
  livingDining: 'Living & Dining',
  office: 'Office',
  outdoors: 'Outdoors',
};

export const items = [
  // Bedroom
  ...category('bedroom', [
    define('Bed, King Size', 70),
    define('Bed, Double Size', 55),
    define('Bed, Single', 40),
    define('Bed, Bunk (set of 2)', 70),
    define('Dresser, Chest of Drawers', 30),
    define('Cedar Chest', 15),
    define('Ironing Board', 10),
    define('Side Tables', 10),
    define('Wardrobe Boxes (30 hangers)', 10),
    define('Mirror', 10),
  ]),

  // Kitchen
  ...category('kitchen', [
    define('Breakfast Chair', 5),
    define('Breakfast Table', 10),
    define('Chair, High', 5),
    define('Kitchen Cabinet', 30),
    define('Serving Cart', 15),
    define('Utility Cabinet', 10),
    define('Refrigerator Small', 40),
    define('Refrigerator Med', 45),
    define('Refrigerator Large or DBdoor', 55),
    define('Freezer Small', 45),
    define('Freezer Medium', 50),
    define('Freezer Large', 60),
    define('Washing Machine', 35),
    define('Dryer', 25),
    define('Stove', 30),
    define('Dishwasher', 35),
    define('Microwave', 10),
  ]),

  // Living & Dining
  ...category('livingDining', [
    define('Sofa 4 Seater', 55),
    define('Sofa 3 Seater', 50),
    define('Sofa 2 Seater', 35),
    define('Sofa Bed', 50),
    define('Sofa Sectional (per section)', 30),
    define('Arm Chair', 10),
    define('Occasional Chair', 15),
    define('Lazy Boy', 25),
    define('Book Case', 20),
    define('Pictures', 5),
    define('Coffee Tables', 5),
    define('End Table', 5),
    define('Wall Unit Small', 15),
    define('Wall Unit Large', 20),
    define('Cupboard Small', 10),
    define('Cupboard Large', 15),
    define('Bureau', 20),
    define('Television', 25),
    define('Television Table', 10),
    define('Video', 5),
    define('Stereo / Hi Fi - Mid', 15),
    define('Stereo / Hi Fi - Large', 20),
    define('Lamps Floor or Pole', 3),
    define('Curtain', 10),
    define('Upright Piano', 60),
    define('Baby Grand Piano', 70),
    define('Grand Piano', 80),
    define('Carpet & Rug', 10),
    define('Clock Grand Father', 20),
    define('Magazine Rack', 2),
    define('Buffet', 30),
    define('Dining Table Small', 20),
    define('Dining Table Mid', 25),
    define('Dining Table (large or glass)', 30),
    define('Dining Chair', 5),
    define('China Cabinet', 25),
    define('Corner Cabinet', 20),
    define('Vacuum Cleaner', 5),
  ]),

  // Office
  ...category('office', [
    define('Bench', 25),
    define('Bookcase, Small', 10),
    define('Bookcase, Mid', 12),
    define('Bookcase, Large', 15),
    define('Cabinet', 25),
    define('Cabinet, metal small', 20),
    define('Chair, arm', 10),
    define('Glass top', 15),
    define('Partitions', 10),
    define('Rack', 3),
    define('Rug', 2),
    define('Safe', 50),
    define('Shelf, metal small', 5),
    define('Shelving, industrial', 8),
    define('Chair, Stackable', 3),
    define('Chair, Upholstered', 30),
    define('Chair, folding', 2),
    define('Coat rack', 2),
    define('Computer desk', 20),
    define('Copier stand', 2),
    define('Credenza', 50),
    define('Desk, executive', 40),
    define('Desk, secretary', 35),
    define('Desk, return', 22),
    define('File 2 drawer', 20),
    define('File 3 drawer', 23),
    define('File 4 drawer', 26),
    define('File 5 drawer', 29),
    define('Showcase', 35),
    define('Sofa, small', 5),
    define('Sofa, large', 50),
    define('Stool', 3),
    define('Table', 12),
    define('Table coffee', 5),
    define('Table, conference', 50),
    define('Table, Drawing', 20),
    define('Table, end', 5),
    define('Table, folding', 10),
    define('Trunk', 10),
  ]),

  // Outdoors
  ...category('outdoors', [
    define('Barbecue or Portable Grill', 10),
    define('Chair, Lawn', 5),
    define('Chair, Porch', 10),
    define('Ladder, Extension', 10),
    define('Lawn Mower', 35),
    define('Outdoor Swings', 30),
    define('Picnic Table', 20),
  ]),
].map((item, index) => ({
  ...item,
  // We'll add the current index as a simple way to identify these items
  id: index.toString(),
}));

/* eslint-disable max-len */
export const unitSizes = [
  defineSize(
    '5x5',
    200,
    'Think of it like a standard closet. Approximately 25 square feet, this space is perfect for about a dozen boxes, a desk and chair, and a bicycle.',
  ),
  defineSize(
    '5x10',
    400,
    'It’s like a standard walk-in closet. Approximately 50 square feet, this unit holds a dozen boxes, a desk and chair, and a mattress and box spring.',
  ),
  defineSize(
    '5x15',
    600,
    'The size of a large walk-in closet, this rental can hold the contents of a small studio apartment, including small furniture, boxes, and bookcases.',
  ),
  defineSize(
    '10x10',
    800,
    'Approximately 100 square feet, this space can hold the contents of several rooms. You could fit an entire bedroom set, a couch, and a kitchen table.',
  ),
  defineSize(
    '10x15',
    1200,
    'Use this space to store the contents of a small house or a two-bedroom apartment: two couches, two mattress sets, a coffee table, fridge, and TV.',
  ),
  defineSize(
    '10x20',
    1600,
    'The size of a one-car garage, this space can hold a fridge, washer and dryer, three mattress sets, a dining room set, bookcases, couches, and boxes.',
  ),
  defineSize(
    '10x25',
    2000,
    'This space can fit a couple of couches, an oversized arm chair, dining room set, fridge, washer and dryer, entertainment nook, bookcases, and more.',
  ),
  defineSize(
    '10x30',
    2400,
    'Fit the contents of a three- to four-bedroom house. This 300 square foot space holds many mattress sets, dining room sets, couches, and boxes.',
  ),
].map(({ volume, ...unitSize }) => ({
  ...unitSize,
  // We don't want to fill these units passed 60% volume for now. This logic may need to be
  // tweaked later because we're doing stupid-simple volumetric comparisons and furniture
  // is not a liquid.
  volume: volume * 0.6,
}));
/* eslint-enable max-len */
