import React from 'react';
import { TRANSLATION_TEXT } from '../../../src/web/store/modules/site';

export const defaultValue = {
  loading: false,
  loaded: false,
  translationTransform: TRANSLATION_TEXT,
};

export const getDefaultValue = () => ({
  ...defaultValue,
});

const CurrentSiteContext = React.createContext(defaultValue);

CurrentSiteContext.displayName = 'CurrentSiteContext';

export default CurrentSiteContext;
