import { MANAGE } from './actions';
import { ALL } from './subjects';

/**
 * @param  {Object}              profile
 * @param {Boolean}              profile.hyper
 * @param {Array[String]}        profile.facilityIds
 * @param {Array[Object]}        profile.permissions
 * @param {Boolean}              profile.permissions.can
 * @param {String}               profile.permissions.action
 * @param {Array[String]|String} profile.permissions.subject
 * @return {Object}
 */
const authorization = (profile) => {
  const check = (action, subjects) => subjects.some(subject => (
    profile.permissions.some(permission => (
      permission.can
      && permission.actions.some(a => [MANAGE, action].includes(a))
      && permission.subjects.some(s => [ALL, subject].includes(s))
    ))
  ));

  const can = (action, ...subjects) => {
    if (!profile) {
      return false;
    }

    if (profile.hyper) {
      return true;
    }

    return check(action, subjects);
  };

  const cannot = (action, ...subjects) => {
    if (!profile) {
      return true;
    }

    if (profile.hyper) {
      return false;
    }

    return !can(action, ...subjects);
  };

  const associatedWithFacility = (facilityId) => {
    if (!profile) {
      return false;
    }

    if (profile.hyper) {
      return true;
    }

    return profile.facilityIds.includes(facilityId);
  };

  const isHyper = () => !!profile?.hyper;

  const associatedFacilityIds = () => {
    if (!profile) {
      return false;
    }
    return profile.facilityIds;
  };

  const isAuthenticated = () => (
    !!profile
  );

  return {
    associatedWithFacility,
    can,
    cannot,
    isAuthenticated,
    isHyper,
    associatedFacilityIds,
  };
};

export default authorization;
