import shallowCompare from 'react-addons-shallow-compare';
import shouldUpdate from './shouldUpdate';

/**
 * This function wraps a component with a "pure" `shouldComponentUpdate` method to increase
 * performance a smidgen. A component wrapped with this will only tell React that it needs
 * to render if the props or state have changed.
 *
 * @return {function(Component: Object): Object} a wrapper function
 */
export default () => shouldUpdate((self, nextProps, nextState) =>
  shallowCompare(self, nextProps, nextState));
