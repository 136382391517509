import React from 'react';

const hydratable = (Widget, id) => {
  const widget = props => (
    <React.Fragment>
      <div data-id={id}>
        <Widget {...props} />
      </div>
      <script
        dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
          __html: JSON.stringify(props),
        }}
        type="application/json"
      />
    </React.Fragment>
  );

  // Add properties from legacy HOCs to the hydratable widget.
  widget.manifestInfo = Widget.manifestInfo;
  widget.authorization = Widget.authorization;

  return widget;
};

export default hydratable;
