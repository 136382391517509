import React from 'react';

export const defaultValue = {
  loading: false,
  loaded: false,
};

export const getDefaultValue = () => ({
  ...defaultValue,
});

const CurrentCompanyContext = React.createContext(defaultValue);

CurrentCompanyContext.displayName = 'CurrentCompanyContext';

export default CurrentCompanyContext;
