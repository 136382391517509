/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import useEventTrigger from '../hooks/useEventTrigger';
import { dataTrackClick } from '../../../src/web/utils/dataLayer';


// Provider component for applicable fees context
const ClickWrapper = ({ children }) => {
  const { addEvent } = useEventTrigger();

  const handleClick = (e) => {
    const widgetId = children?.props?.children?.[0]?.props?.componentId;
    const payload = e.target.dataset;
    if (payload && Object.keys(payload).length > 0) {
      const eventName = payload.event ? dataTrackClick(payload.event) : dataTrackClick(`widget-${widgetId}`);
      addEvent(eventName, { ...payload, widgetId }, 'click');
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div onClick={handleClick}>
      {children}
    </div>
  );
};

export default ClickWrapper;
