const SHOW_MEDIAGALLERY = 'voyager/mediaGallery/SHOW';
const CLOSE_MEDIAGALLERY = 'voyager/mediaGallery/CLOSE';
const TOGGLE_MEDIAGALLERY = 'voyager/mediaGallery/TOGGLE';
const UPLOAD_IMAGES = 'voyager/mediaGallery/UPLOAD_IMAGES';
const SET_MEDIUM = 'voyager/mediaGallery/SET_MEDIUM';
const SELECTED_MEDIUM = 'voyager/mediaGallery/SELECTED_MEDIUM';
const INITIAL_IMAGES = 'voyager/mediaGallery/INITIAL_IMAGES';
const CLEAR_MEDIA_GALLERY = 'voyager/mediaGallery/CLEAR_INITIAL_IMAGES';
const BACKGROUND_TYPE = 'voyager/backgroundSettings/BACKGROUND_SETTINGS';
const UPLOAD_IMAGES_FROM_GALLERY = 'voyager/mediaGallery/UPLOAD_IMAGES_FROM_GALLERY';
const SAVE_BUTTON = 'voyager/mediaGallery/SAVE_BUTTON';

// Reducer
const initialState = {
  showMediaGallery: false,
  images: [],
  usedMedia: null,
  selectedMedium: null,
  initialImages: [],
  backgroundType: null,
  saveButtonClicked: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_MEDIAGALLERY:
      return {
        ...state,
        showMediaGallery: true,
      };
    case CLOSE_MEDIAGALLERY:
      return {
        ...state,
        showMediaGallery: false,
      };
    case TOGGLE_MEDIAGALLERY:
      return {
        ...state,
        showMediaGallery: !state.showMediaGallery,
      };
    case UPLOAD_IMAGES:
      return {
        ...state,
        images: action.images,
      };
    case UPLOAD_IMAGES_FROM_GALLERY:
      return {
        ...state,
        images: [...state.images,
          { ...action.selectedMedium,
            file: `${action.selectedMedium?.companyId}/${action.selectedMedium?.filename}` }],
        showMediaGallery: false,
        selectedMedium: null,
      };
    case SET_MEDIUM:
      return {
        ...state,
        usedMedia: action.medium,
      };
    case SELECTED_MEDIUM:
      return {
        ...state,
        selectedMedium: action.medium,
      };
    case INITIAL_IMAGES:
      return {
        ...state,
        initialImages: action.images,
        images: action.images,
      };
    case BACKGROUND_TYPE:
      return {
        ...state,
        backgroundType: action.backgroundType,
      };
    case SAVE_BUTTON:
      return {
        ...state,
        saveButtonClicked: true,
      };
    case CLEAR_MEDIA_GALLERY:
      return initialState;
    default:
      return state;
  }
}

export function isMediaGalleryOpen(state) {
  return state.mediaGallery.showMediaGallery;
}

export function getImages(state) {
  return state.mediaGallery.images;
}

export function getUsedMedia(state) {
  return state.mediaGallery.usedMedia;
}

export function getSelectedMedium(state) {
  return state.mediaGallery.selectedMedium;
}

export function getInitialImages(state) {
  return state.mediaGallery.initialImages;
}

export function getBackgroundType(state) {
  return state.mediaGallery.backgroundType;
}

export function getSaveButtonClicked(state) {
  return state.mediaGallery.saveButtonClicked;
}

export function toggleMediaGallery() {
  return { type: TOGGLE_MEDIAGALLERY };
}

export function setSelectedMedia(medium) {
  return { type: SELECTED_MEDIUM, medium };
}

// Action Creators
export function showMediaGallery() {
  return { type: SHOW_MEDIAGALLERY };
}

export function uploadImages(images) {
  return { type: UPLOAD_IMAGES, images };
}

export function closeMediaGallery() {
  return { type: CLOSE_MEDIAGALLERY };
}

export function setUsedMedium(medium) {
  return { type: SET_MEDIUM, medium };
}

export function setInitialImages(images) {
  return { type: INITIAL_IMAGES, images };
}

export function clearMediaGallery() {
  return { type: CLEAR_MEDIA_GALLERY };
}

export function uploadImagesFromGallery(selectedMedium) {
  return { type: UPLOAD_IMAGES_FROM_GALLERY, selectedMedium };
}

export function setBackgroundType(backgroundType) {
  return { type: BACKGROUND_TYPE, backgroundType };
}

export function saveButtonClicked() {
  return { type: SAVE_BUTTON };
}
