// These constants map to the available software providers in FMS
// https://github.com/rednovalabs/fms/blob/master/lib/software_provider.rb
export const STOREDGE = 'storedge';
const STOREDGE_DISPLAY_NAME = 'storEDGE';

export const SITELINK = 'sitelink';
const SITELINK_DISPLAY_NAME = 'SiteLink';

export const SOFTWARE_PROVIDERS = [
  STOREDGE,
  SITELINK,
];

export const SOFTWARE_PROVIDER_DISPLAY_NAMES = {
  [STOREDGE]: STOREDGE_DISPLAY_NAME,
  [SITELINK]: SITELINK_DISPLAY_NAME,
};
