import omit from 'lodash/omit';
import { items, unitSizes } from 'utils/storageCalculator';

const defaultCategory = items[0].category; // Pick the first category as the starting one
const filterItems = category => items.filter(item => item.category === category);

// Actions
const CHANGE_CATEGORY = 'voyager/storageCalculator/CHANGE_CATEGORY';
const CHANGE_COUNT = 'voyager/storageCalculator/CHANGE_COUNT';
const RESET_COUNTS = 'voyager/storageCalculator/RESET_COUNTS';

// Reducer
const initialState = {
  category: defaultCategory,
  items: filterItems(defaultCategory),
  counts: {},
  volume: 0,
  unitSize: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_CATEGORY:
      return {
        ...state,
        category: action.category,
        items: filterItems(action.category),
      };
    case CHANGE_COUNT: {
      let counts;
      // If we got non-zero falsy value, remove this item from the count listing
      if (!action.count && action.count !== 0) {
        counts = omit(state.counts, action.id);
      } else {
        const count = parseInt(action.count, 10);
        if (Number.isNaN(count)) {
          return state;
        }

        counts = {
          ...state.counts,
          [action.id]: Math.max(0, count),
        };
      }

      // Sum up the volumes of all the selected items
      const volume = Object.keys(counts).reduce((accumulator, itemId) => {
        const item = items.find(({ id }) => id === itemId);

        return accumulator + (counts[itemId] * item.size);
      }, 0);

      // Find a unit size and fits
      let unitSize = null;
      if (volume) {
        unitSize = unitSizes.find(unit => unit.volume >= volume);

        if (!unitSize) {
          unitSize = unitSizes[unitSizes.length - 1];
        }
      }

      return {
        ...state,
        counts,
        volume,
        unitSize,
      };
    }
    case RESET_COUNTS:
      return {
        ...state,
        counts: {},
        volume: 0,
        unitSize: null,
      };
    default:
      return state;
  }
}

// Action Creators
export function changeCategory(category) {
  return {
    type: CHANGE_CATEGORY,
    category,
  };
}

export function changeItemCount(id, count) {
  return { type: CHANGE_COUNT, id, count };
}

export function resetCounts() {
  return { type: RESET_COUNTS };
}
