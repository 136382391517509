// useEventTrigger.js
import { useSelector, useDispatch } from 'react-redux';
import { trackDataLayerClick, trackDataLayerSubmit, trackDataLayerView } from '../../../src/web/utils/dataLayer';
import { addEventToStore } from '../../../src/web/store/modules/websiteEvents';


const useEventTrigger = () => {
  const page = useSelector(state => state?.page);
  const userLocation = useSelector(state => state?.user?.userLocation);
  const dispatch = useDispatch();

  // Function to add an event to the queue
  const addEvent = (event, payload, action) => {
    if (action === 'click') {
      trackDataLayerClick(event, payload);
    } else if (action === 'submit') {
      trackDataLayerSubmit(event, payload);
    } else if (action === 'view') {
      trackDataLayerView(event, payload);
    }
    const eventName = Object.values(event)[0];
    const updatedEvent = { ...payload,
      event: eventName,
      pageId: page?.id,
      facilityId: page?.facilityId,
      pageVersion: page?.publishedVersion?.id,
      userLat: userLocation?.latitude,
      userLong: userLocation?.longitude,
      timeStamp: (new Date()).toISOString() };
    dispatch(addEventToStore(updatedEvent));
  };

  const addOnlyEvent = (event) => {
    dispatch(addEventToStore(event));
  };

  return { addEvent, addOnlyEvent };
};

export default useEventTrigger;
