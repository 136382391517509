import md5Hash from 'md5';

// Actions
const LOAD_START = 'voyager/clientMiddleware/LOAD_START';
const LOAD_SUCCESS = 'voyager/clientMiddleware/LOAD_SUCCESS';
const LOAD_FAIL = 'voyager/clientMiddleware/LOAD_FAIL';

// Reducer
const initialState = {
  loading: 0,
  loadedActions: [],
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_START:
      return {
        ...state,
        loading: state.loading + 1,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loadedActions: [
          ...state.loadedActions,
          action.key,
        ],
        loading: Math.max(state.loading - 1, 0),
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: Math.max(state.loading - 1, 0),
        errors: [
          ...state.errors,
          action.error,
        ],
      };
    default:
      return state;
  }
}

// Action Creators
export function start() {
  return {
    type: LOAD_START,
  };
}

export function success(action) {
  return {
    type: LOAD_SUCCESS,
    key: serializeAction(action),
  };
}

export function fail(error) {
  return {
    type: LOAD_FAIL,
    error,
  };
}

// Global
export function isLoaded(globalState, action) {
  const key = serializeAction(action);
  return globalState.clientMiddleware.loadedActions.indexOf(key) !== -1;
}

export function serializeAction(action) {
  return md5Hash(JSON.stringify(action));
}
