import { query } from '../../utils/graphql';

// Actions
const SUBMIT = 'voyager/website_events/SUBMIT';
const SUBMIT_SUCCESS = 'voyager/website_events/SUBMIT_SUCCESS';
const SUBMIT_FAIL = 'voyager/website_events/SUBMIT_FAIL';
const ADD_EVENT = 'voyager/website_events/ADD_EVENT';
const CLEAR_EVENT = 'voyager/website_events/CLEAR_EVENT';

// Reducer
const initialState = {
  submitting: false,
  error: null,
  currentEvents: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUBMIT:
      return {
        ...state,
        submitting: true,
      };
    case ADD_EVENT:
      state?.currentEvents?.push(action.event);
      return {
        ...state,
      };
    case CLEAR_EVENT:
      return {
        ...state,
        currentEvents: [],
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case SUBMIT_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.error.length > 0 ? action.error[0].message : 'Unable to send event',
      };
    default:
      return state;
  }
}

// Action Creators
export function submit({
  event,
}) {
  return {
    types: [SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAIL],
    event,
    force: true,
    promise: query`
      mutation CreateWebsiteEvent(${{ event }}: JSON!) {
        addEvent(event: $event){
          event
        }
      }
    `,
  };
}

export function addEventToStore(event) {
  return {
    type: ADD_EVENT,
    event,
  };
}

export function clearEvents() {
  return {
    type: CLEAR_EVENT,
  };
}

// Selectors
// Selectors
export const getCurrentEvents = state => (
  state.currentEvents
);
