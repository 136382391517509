
export const BLOG_CATEGORY_PAGE = 'voyager/pages/types/BLOG_CATEGORY_PAGE';
export const BLOG_HOME_PAGE = 'voyager/pages/types/BLOG_HOME_PAGE';
export const BLOG_PAGE = 'voyager/pages/types/BLOG_PAGE';
export const HOME_PAGE = 'voyager/pages/types/HOME_PAGE';
export const FACILITY_LINK = 'voyager/pages/types/FACILITY_LINK';
export const FACILITY_LISTING = 'voyager/pages/types/FACILITY_LISTING';
export const FACILITY_PAGE = 'voyager/pages/types/FACILITY_PAGE';
export const MISC_PAGE = 'voyager/pages/types/MISC_PAGE';
export const FACILITIES_LIST_PAGE = 'voyager/pages/types/FACILITIES_LIST_PAGE';
export const PRIVACY_PAGE = 'voyager/pages/types/PRIVACY_PAGE';
export const TERMS_PAGE = 'voyager/pages/types/TERMS_PAGE';
export const PAY_ONLINE_PAGE = 'voyager/pages/types/PAY_ONLINE_PAGE';
export const PAGE_LAYOUT = 'voyager/pages/types/PAGE_LAYOUT';

export const uniquePageTypes = [
  HOME_PAGE,
  FACILITIES_LIST_PAGE,
  TERMS_PAGE,
  PRIVACY_PAGE,
  PAY_ONLINE_PAGE,
  BLOG_HOME_PAGE,
  BLOG_PAGE,
  BLOG_CATEGORY_PAGE,
];

export const PAGE_TYPE_ENUM = [
  BLOG_CATEGORY_PAGE,
  BLOG_HOME_PAGE,
  BLOG_PAGE,
  HOME_PAGE,
  FACILITY_LINK,
  FACILITY_LISTING,
  FACILITY_PAGE,
  MISC_PAGE,
  FACILITIES_LIST_PAGE,
  PRIVACY_PAGE,
  TERMS_PAGE,
  PAY_ONLINE_PAGE,
  PAGE_LAYOUT,
];
