// Actions
const UPLOAD = 'voyager/assets/UPLOAD';
const UPLOAD_PROGRESS = 'voyager/assets/UPLOAD_PROGRESS';
const UPLOAD_SUCCESS = 'voyager/assets/UPLOAD_SUCCESS';
const UPLOAD_FAIL = 'voyager/assets/UPLOAD_FAIL';
const CLEAR = 'voyager/assets/CLEAR';

// Reducer
const initialState = {
  currentFile: {},
  error: null,
  uploadPercent: null,
  uploadInfo: [],
  uploading: false,
  uploadSuccess: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPLOAD:
      return {
        ...state,
        currentFile: {},
        error: null,
        uploading: true,
        uploadPercent: 0,
        uploadSuccess: false,
      };
    case UPLOAD_PROGRESS:
      return {
        ...state,
        uploadPercent: action.percent,
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        currentFile: { file: action.data.asset.name },
        error: null,
        uploading: false,
        uploadSuccess: true,
        uploadPercent: 100,
      };
    case UPLOAD_FAIL:
      return {
        ...state,
        error: action.error.message,
        uploading: false,
        uploadSuccess: false,
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

// Selectors

export const getCurrentFile = state => (
  state.assets.currentFile
);

export const getUploadSuccess = state => (
  state.assets.uploadSuccess
);

// Action Creators
function progress(percent) {
  return {
    type: UPLOAD_PROGRESS,
    percent,
  };
}

export function upload(file) {
  // Stuff the file into form data to transmit it
  const data = new FormData();
  data.append('asset', file);
  const query = 'mutation+UploadFile{asset:uploadAsset{name}}';

  return {
    types: [UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAIL],
    force: true,
    promise: (client, { dispatch }) => client.post(`/graphql?query=${query}`, {
      data,
      modify: (request) => {
        // When progress is reported back to the browser, fire an event
        request.on('progress', ({ percent }) => {
          dispatch(progress(percent));
        });
      },
    }),
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}
