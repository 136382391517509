import fetch from 'unfetch';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { API_GRAPHQL_URL } from '../../../../api';

// eslint-disable-next-line no-underscore-dangle
const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);

const link = createUploadLink({
  uri: API_GRAPHQL_URL,
  fetch,
});

// For more information, see:
// https://www.apollographql.com/docs/react/features/server-side-rendering.html
const apolloClient = new ApolloClient({
  cache,
  link,
});

export default apolloClient;
