import { MIXPANEL_PAGE_VIEW_DATA_ID } from './properties';

export default () => {
  const mixpanelNode = document.getElementById(MIXPANEL_PAGE_VIEW_DATA_ID);
  if (mixpanelNode) {
    import('../../utils/mixpanel').then(({ default: mixpanel }) => {
      const trackProperties = JSON.parse(mixpanelNode.innerHTML);
      mixpanel.trackPageView(trackProperties);
      mixpanelNode.remove();
    });
  }
};
