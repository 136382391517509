import React from 'react';
import Script from 'react-load-script';

export default ({ url }) => Component => class ScriptLoader extends React.Component {
  state = {
    isLoaded: false,
  };

  handleCreate = () => {};

  handleError = () => {};

  handleLoad = () => {
    this.setState({ isLoaded: true });
  };

  renderScript() {
    return (
      <Script
        url={url}
        onCreate={this.handleCreate}
        onError={this.handleError}
        onLoad={this.handleLoad}
      />
    );
  }

  render() {
    const { isLoaded } = this.state;
    if (!isLoaded) {
      return this.renderScript();
    }

    return <Component {...this.props} />;
  }
};
