export const API_GRAPHQL_PATH = '/v2/graphql';

export const PREFIXED_API_GRAPHQL_PATH = `/api${API_GRAPHQL_PATH}`;

const {
  VOYAGER_API_PROTOCOL: protocol,
  VOYAGER_API_HOST: host,
  VOYAGER_API_PORT: port,
} = process.env;

export const API_GRAPHQL_URL = typeof window === 'undefined'
  ? `${protocol}://${host}:${port}${API_GRAPHQL_PATH}`
  : PREFIXED_API_GRAPHQL_PATH;
