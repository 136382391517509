import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
// Website Reducers
import assets from './modules/assets';
import clientMiddleware from './modules/clientMiddleware';
import company from './modules/company';
import contact from './modules/contact';
import coupon from './modules/coupon';
import facilities from './modules/facilities';
import googleReviews from './modules/googleReviews';
import hostnames from './modules/hostnames';
import lead from './modules/lead';
import page from './modules/page';
import pages from './modules/pages';
import reservations from './modules/reservations';
import reviews from './modules/reviews';
import routes from './modules/routes';
import site from './modules/site';
import storageCalculator from './modules/storageCalculator';
import user from './modules/user';
import mediaGallery from './modules/mediaGallery';
import websiteEvents from './modules/websiteEvents';
// Admin Reducers
// import builder from './modules/builder';
// import certificates from './modules/certificates';
// import events from './modules/events';
// import files from './modules/files';
// import hyper from './modules/hyper';
// import pageBuilder from './modules/pageBuilder';
// import pageSettings from './modules/pageSettings';

export default combineReducers({
  assets,
  clientMiddleware,
  company,
  contact,
  coupon,
  facilities,
  googleReviews,
  hostnames,
  lead,
  page,
  pages,
  reservations,
  reviews,
  routes,
  site,
  storageCalculator,
  user,
  // builder,
  // certificates,
  // events,
  // files,
  // hyper,
  // pageBuilder,
  // pageSettings,
  form,
  mediaGallery,
  websiteEvents,
});
