// The constants in this file are used by companies to monitor events through
// the data layer. Only change these values after consulting with product as they
// are responsible for the events triggers tied to these ids and would be very
// mad if they suddenly stopped working.

// All data layer event identifiers are HTML data-* attributes with the `track`
// prefix. Using a data attribute reduces the chance they'll be accidentally
// overwritten or renamed by webpack in the way a class may be. Prefixing them
// with `track` reduces the chance they will conflict with other data attributes.
// https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes

// disable eslint for this file to allow longer id constants
/* eslint-disable */

const DATA_TRACK = 'data-track';

const dataTrack = id => ({ [DATA_TRACK]: id });

// Button or link click identifiers.
const DATA_TRACK_CLICK = `${DATA_TRACK}-click`;
const DATA_TRACK_VIEW = `${DATA_TRACK}-view`;

export const dataTrackClick = id => ({ [DATA_TRACK_CLICK]: id });
const dataTrackView = id => ({ [DATA_TRACK_VIEW]: id });

// Voyager click event tracking.
export const TRACK_CLICK_RESERVE = dataTrackClick('reserve');
export const TRACK_CLICK_UNITS_TABLE_CALL = dataTrackClick('units-table-call');
export const TRACK_CLICK_MOVE_IN = dataTrackClick('move-in');
export const TRACK_CLICK_MOVE_IN_NOW = dataTrackClick('move-in-now');
export const TRACK_CLICK_FACILITY_INFO_CALL = dataTrackClick('facility-info-call');
export const TRACK_CLICK_TEXT_ME_DIRECTIONS = dataTrackClick('textDirectionsClick');

// Additional click events for data layer analysis.
export const TRACK_CLICK_SIZE_SORT = dataTrackClick('sizeSort');
export const TRACK_CLICK_PRICE_SORT = dataTrackClick('priceSort');
export const TRACK_CLICK_FILTER_APPLIED  = dataTrackClick('filterApplied');
export const TRACK_CLICK_CATEGORY_APPLIED  = dataTrackClick('categoryApplied');
export const TRACK_CLICK_PROMO_BANNER = dataTrackClick('promoBannerClicked');
export const TRACK_CLICK_SIZE_GUIDE = dataTrackClick('sizeGuideClicked');
export const TRACK_CLICK_TIER_SELECTED = dataTrackClick('unitTierSelected');

// Form submission identifiers.
const DATA_TRACK_SUBMIT = `${DATA_TRACK}-submit`;

const dataTrackSubmit = id => ({ [DATA_TRACK_SUBMIT]: id });

// Voyager success event tracking.
export const TRACK_SUBMIT_RESERVATION = dataTrackSubmit('reservation');

export const TRACK_SUBMIT_CURIOUS_ABOUT_STORAGE_FORM_SUCCESS = dataTrackSubmit('curiousAboutStorageFormSuccess');
export const TRACK_SUBMIT_RIN_ZERO_SUCCESS_CONFIRMATION = dataTrackSubmit('rin0SuccessConfirmation');
export const TRACK_SUBMIT_RIN_SUCCESS_CONFIRMATION = dataTrackSubmit('rinSuccessConfirmation');
export const TRACK_SUBMIT_SEND_TEXT_SUCCESS = dataTrackSubmit('sendTextSuccess');
export const TRACK_SUBMIT_RIN_STEP_ONE = dataTrackSubmit('rinStep1');
export const TRACK_SUBMIT_RIN_STEP_TWO = dataTrackSubmit('rinStep2');

export const TRACK_CLICK_REVIEW_US_CLICK = dataTrackClick('reviewUsClick');
export const TRACK_CLICK_VIDEO_PLAY = dataTrackClick('videoPlay');

// Additional click events for data layer analysis.
export const TRACK_SUBMIT_PROMO_CODE = dataTrackSubmit('promoCodeSubmit');


export const TRACK_IMAGE_GALLERY_INTERACTION = dataTrackClick('imageGalleryInteraction');


export const TRACK_PAGE_VIEW = dataTrackView('pageView');
export const TRACK_CONTENT_VIEW = dataTrackView('contentView');
// Event constants
export const UK_GET_ONLINE_QUOTES = 'ukGatedQuoteFormSuccess';

export function trackDataLayer(action, event, payload = {}) {
  window.dataLayer = window.dataLayer || [];
  const obj = { ...payload };
  if(action === 'click'){
    obj.event = event[DATA_TRACK_CLICK]
  } else if(action === 'submit'){
    obj.event = event[DATA_TRACK_SUBMIT]
  } else if(action === 'view'){
    obj.event = event[DATA_TRACK_VIEW]
  } else {
    obj.event = event
  };
  window.dataLayer.push(obj);
}

export function trackDataLayerEvent(event, payload = {}) {
  trackDataLayer('event', event, payload);
}

export function trackDataLayerClick(event, payload = {}) {
  trackDataLayer('click', event, payload);
}

export function trackDataLayerSubmit(event, payload = {}) {
  trackDataLayer('submit', event, payload);
}

export function trackDataLayerView(event, payload = {}) {
  trackDataLayer('view', event, payload);
}