import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

/**
 * This HOC allows us to apply shouldComponentUpdate logic to functional components easily.
 *
 * @param {function(self: Object, nextProps: Object, nextState: Object): bool} shouldUpdate - the should update function
 * @return {function(Component: Object): Object} a wrapper function
 */
export default shouldUpdate => (Component) => {
  // Disable these optimizations in testing
  if (process.env.NODE_ENV === 'test') {
    return Component;
  }

  const name = Component.displayName || Component.name || 'Component';
  class ShouldUpdateComponent extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
      return shouldUpdate(this, nextProps, nextState);
    }

    render() {
      return <Component {...this.props} />;
    }
  }
  ShouldUpdateComponent.displayName = `ShouldUpdate(${name})`;
  return hoistNonReactStatic(ShouldUpdateComponent, Component);
};
