/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import createStore from 'store/create';
import ApiClient from 'helpers/ApiClient';
import ApolloClientProvider from 'modules/react/components/stateful/ApolloClientProvider';
import CurrentPageClientProvider from 'modules/Page/containers/CurrentPageClientProvider';
import MixpanelPageView from 'modules/MixpanelPageView/client';
import GoogleAnalyticsAddons from 'modules/GoogleAnalyticsAddons/client';
import dynamic from '../../website/widgets/dynamic/client';
import AppProvider from './containers/AppProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import 'theme/icons/style.scss';
import '@storable/canaveral-components/styles.css';
import '@storable/canaveral-rte/styles.css';
import '@glidejs/glide/dist/css/glide.core.min.css';
import './theme/bootstrap-overrides.css';
import './theme/facility-globals.scss';
import 'intersection-observer';
// import styles index files for stateful components, dynamic widgets needing to always be loaded
import '../../website/widgets/dynamic/styles';
import '../../website/components/stateful/styles';
// Import styles index files for stateless components, static widgets, and sections
import '../../website/components/stateless/styles';
import '../../modules/react/components/stateless/styles';
import '../../website/widgets/static/styles';
import './components/sections/styles';
import './components/styles';
import './containers/styles';
import './modules/PrintCoupon/styles';
import CookieBannerContainer from '../../website/containers/CookieBannerContainer';

if (!Intl.PluralRules) {
  import('@formatjs/intl-pluralrules/polyfill');
  import('@formatjs/intl-pluralrules/dist/locale-data/en');
  import('@formatjs/intl-pluralrules/dist/locale-data/fr');
  import('@formatjs/intl-pluralrules/dist/locale-data/es');
}

if (!Intl.RelativeTimeFormat) {
  import('@formatjs/intl-relativetimeformat/polyfill');
  import('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  import('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
  import('@formatjs/intl-relativetimeformat/dist/locale-data/es');
}

const { BUGSNAG_API_KEY_FE, BUGSNAG_ENVIRONMENT } = process.env;

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY_FE,
  plugins: [new BugsnagPluginReact()],
  appType: 'website_frontend',
  releaseStage: BUGSNAG_ENVIRONMENT,
});
BugsnagPerformance.start({ apiKey: BUGSNAG_API_KEY_FE });
const BugsnagWebFEErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const client = new ApiClient();
const initialState = window.__data;

const store = createStore(client, initialState);

// Find all hydratable react applications on the page
const reactAppNodes = document.querySelectorAll('[data-id]');

// Find all carousel nodes
const carouselNodes = document.querySelectorAll('[data-glide]');

// Initialize non react carousels (used to avoid hydrating sections with dynamic UI elements)
if (carouselNodes && carouselNodes.length) {
  import('@glidejs/glide').then(({ default: Glide }) => {
    carouselNodes.forEach((node) => {
      const config = JSON.parse(node.innerHTML);
      node.remove();
      const glide = new Glide(`#${CSS.escape(config.carouselId)}`, {
        startAt: 0,
        perView: 1,
        gap: '25px',
        peek: {
          before: '50px',
          after: '50px',
        },
      });
      glide.mount();
    });
  });
}

// Initialize react applications
let cookieBannerRendered = false;

reactAppNodes.forEach((node) => {
  const props = JSON.parse(node.nextSibling.innerHTML);

  node.nextSibling.remove();

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (!entry.isIntersecting) {
        return;
      }
      dynamic[node.dataset.id]().then(({ default: Widget }) => {
        const root = createRoot(node);
        root.render(
          <BugsnagWebFEErrorBoundary>
            <ApolloClientProvider>
              <Provider store={store} key="provider">
                <AppProvider>
                  <CurrentPageClientProvider>
                    <Widget {...props} />
                    {!cookieBannerRendered && (
                      ReactDOM.createPortal(
                        <CookieBannerContainer />,
                        document.getElementById('cookiebanner'),
                      )
                    )}
                    {!cookieBannerRendered && (cookieBannerRendered = true)}
                  </CurrentPageClientProvider>
                </AppProvider>
              </Provider>
            </ApolloClientProvider>
          </BugsnagWebFEErrorBoundary>,
        );
        observer.unobserve(node);
      });
    },
    {
      rootMargin: '50px',
    },
  );
  observer.observe(node);
});

// Send out mixpanel page view event
MixpanelPageView();

// Fire addons to push to the data layer
GoogleAnalyticsAddons();
