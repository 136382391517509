import React, { useEffect, useState, createContext } from 'react';
import gql from 'graphql-tag';
import useQuery from '../hooks/useQuery';
import { SITELINK } from '../../../constants/softwareProviders';

// GraphQL query for fetching applicable fees
const GET_APPLICABLE_FEES = gql`
  query ApplicableFees {
    applicableFees {
      id
      companyId
      softwareProvider
      tierPricingEnabled
      allFees {
        id
        unitGroupId
        tierId
        unitTypeId
        totalFee
        fees {
          id
          productCode
          total
          description
          kind
        }
      }
      feeSummary {
        id
        name
        unitGroupId
        isMinimum
        totalFee
        siteUnitCategoryId
        minPrice
        fees {
          id
          productCode
          total
          description
          kind
        }
      }
    }
  }
`;

const GET_APPLICABLE_FEE_SETTINGS = gql`
  query ApplicableFeeSettings {
    applicableFeeSettings {
      id
      type
      states {
        enabled
        state
      }
    }
  }
`;

// Default context value
const defaultValue = {
  loading: false,
  errors: null,
  applicableFee: [],
  stateSettings: [],
};

// Create context for applicable fees
const ApplicableFeeContext = createContext(defaultValue);
ApplicableFeeContext.displayName = 'ApplicableFeeContext';

// Provider component for applicable fees context
export const ApplicableFeeProvider = ({ children, showApplicableFees }) => {
  const [applicableFeeData, setApplicableFeeData] = useState(defaultValue);

  const { data, loading, errors } = useQuery(GET_APPLICABLE_FEES, {
    fetchPolicy: 'no-cache',
    ssr: false,
    skip: !showApplicableFees,
  });

  const { data: settings } = useQuery(GET_APPLICABLE_FEE_SETTINGS, {
    fetchPolicy: 'no-cache',
    ssr: false,
    skip: !showApplicableFees,
  });

  useEffect(() => {
    setApplicableFeeData(prevState => ({
      ...prevState,
      loading,
      errors,
    }));

    if (showApplicableFees && data) {
      const applicableFees = data.applicableFees || [];

      const feesLookup = applicableFees.reduce((acc, fee) => {
        const allFees = fee.allFees.reduce((subAcc, subFee) => {
          const updatedSubAcc = { ...subAcc };
          if (fee.softwareProvider === SITELINK) {
            updatedSubAcc.FEES = subFee;
          } else if (fee.tierPricingEnabled) {
            updatedSubAcc[`${subFee.unitGroupId}-${subFee.tierId}`] = subFee;
          } else {
            updatedSubAcc[subFee.unitGroupId] = subFee;
          }
          return updatedSubAcc;
        }, {});

        const feeSummary = fee.feeSummary.reduce((sumAcc, summaryFee) => {
          const updatedSumAcc = { ...sumAcc };
          if (summaryFee.isMinimum) {
            updatedSumAcc.MINIMUM = summaryFee;
          }
          updatedSumAcc[summaryFee.id] = summaryFee;
          return updatedSumAcc;
        }, {});

        acc[fee.id] = {
          ...fee,
          allFees,
          feeSummary,
        };

        return acc;
      }, {});

      setApplicableFeeData({
        loading,
        errors,
        applicableFee: feesLookup,
        stateSettings: settings,
      });
    }
  }, [settings, showApplicableFees, loading, errors, data]);

  return (
    <ApplicableFeeContext.Provider value={applicableFeeData}>
      {children}
    </ApplicableFeeContext.Provider>
  );
};

export default ApplicableFeeContext;
