import { useEffect } from 'react';
import PropTypes from 'prop-types';
import hydratable from '../../../hocs/hydratable';
import { ID } from './properties';
import useEventTrigger from '../../../../modules/react/hooks/useEventTrigger';
import { TRACK_CONTENT_VIEW } from '../../../../src/web/utils/dataLayer';


const propTypes = {
  event: PropTypes.object,
};

const defaultProps = {
  event: {},
};

const ContetViewTrigger = ({
  event,
}) => {
  const { addEvent } = useEventTrigger();

  useEffect(() => {
    addEvent(TRACK_CONTENT_VIEW, event, 'view');
  }, []);

  return null;
};

ContetViewTrigger.propTypes = propTypes;
ContetViewTrigger.defaultProps = defaultProps;

export default hydratable(ContetViewTrigger, ID);
