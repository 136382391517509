import { GOOGLE_ANALYTICS_ADDONS_ID } from './properties';

export default () => {
  const googleAnalyticsNode = document.getElementById(GOOGLE_ANALYTICS_ADDONS_ID);
  if (googleAnalyticsNode) {
    const { storeNumber } = JSON.parse(googleAnalyticsNode.innerHTML);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ storeId: storeNumber });
  }
};
