import { query } from 'utils/graphql';

// Actions
const REQUEST = 'voyager/coupon/REQUEST';
const REQUEST_SUCCESS = 'voyager/coupon/REQUEST_SUCCESS';
const REQUEST_FAIL = 'voyager/coupon/REQUEST_FAIL';
const SHOW_MODAL = 'voyager/coupon/SHOW_MODAL';
const HIDE_MODAL = 'voyager/coupon/HIDE_MODAL';

// Reducer
const initialState = {
  requesting: false,
  requested: false,
  errorMessage: '',
  show: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        requested: false,
        requesting: false,
        show: true,
      };
    case HIDE_MODAL:
      return {
        ...state,
        requested: false,
        requesting: false,
        show: false,
      };
    case REQUEST:
      return {
        ...state,
        requesting: true,
        requested: false,
        errorMessage: '',
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
        requested: true,
      };
    case REQUEST_FAIL:
      return {
        ...state,
        requesting: false,
        errorMessage: action.error.response.body.errors[0].message,
      };
    default:
      return state;
  }
}

// Action Creators
export function showModal() {
  return { type: SHOW_MODAL };
}

export function hideModal() {
  return { type: HIDE_MODAL };
}

export function request(facilityId, coupon, tenant) {
  if (!tenant.email && !tenant.phone) {
    return { type: REQUEST_SUCCESS };
  }

  return {
    types: [REQUEST, REQUEST_SUCCESS, REQUEST_FAIL],
    force: true,
    promise: query`
      mutation RequestCoupon(
        ${{ facilityId }}: String!
        ${{ tenant }}: CouponTenantInput!
        ${{ coupon }}: CouponInput!
      ) {
        tenant: requestCoupon(facilityId: $facilityId, tenant: $tenant, coupon: $coupon) {
          id
          firstName
          lastName
          email
        }
      }
    `,
  };
}
