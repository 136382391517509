import shallowEqual from 'shallowequal';
import shouldUpdate from './shouldUpdate';

/**
 * This function wraps a component with a "pure" `shouldComponentUpdate` method to increase
 * performance a smidgen. A component wrapped with this will only tell React that it needs
 * to render if the props have changed. If your component uses state, take a look at using
 * `pureWithState` instead.
 *
 * @param {string[]=} monitor - If specified, only monitor changes to these props
 * @return {function(Component: Object): Object} a wrapper function
 */
export default (monitor = null) => shouldUpdate((self, nextProps) => {
  if (!monitor) {
    return !shallowEqual(self.props, nextProps);
  }

  const current = {};
  const next = {};
  monitor.forEach((prop) => {
    current[prop] = self.props[prop];
    next[prop] = nextProps[prop];
  });
  return !shallowEqual(current, next);
});
