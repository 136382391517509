import { isLoaded, serializeAction, start, success, fail } from 'store/modules/clientMiddleware';

export default function clientMiddleware(client) {
  const activePromises = [];

  return (store) => {
    const { dispatch, getState } = store;

    return next => (action) => {
      // This is redux-thunk
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const {
        promise, nextPromise, types, force, ...rest
      } = action; // eslint-disable-line no-unused-vars
      // If this action doesn't have a promise, fall through
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;

      // If this promise has already been resolved and it's not being forced, resolve immediately
      if (!force && isLoaded(getState(), action)) {
        return Promise.resolve();
      }

      // If this promise is already being loaded, wait
      // Potential API change: Should we account for the force flag here?
      const fingerprint = serializeAction(action);
      const candidateIndex = activePromises.indexOf(fingerprint);
      if (candidateIndex !== -1) {
        return activePromises[candidateIndex];
      }

      next(start());
      next({ ...rest, type: REQUEST });

      // Execute the promise function
      const actionPromise = promise(client, store);
      activePromises.push(fingerprint);
      actionPromise.then(
        (result) => {
          const successAction = { ...rest, type: SUCCESS };
          // Throw in the request data if we have it
          if (result) {
            successAction.data = result.data;
          }
          next(successAction);
          next(success(action));
          activePromises.splice(activePromises.indexOf(fingerprint), 1);
        },
        (error) => {
          if (__SERVER__) {
            import('modules/logger').then(({ default: logger }) => {
              if (error.message !== 'Unauthorized') {
                logger.error(`Redux action ${REQUEST} failed`, error, { label: 'Redux' });
              }
            });
          }

          next({ ...rest, error, type: FAILURE });
          next(fail(error));
          activePromises.splice(activePromises.indexOf(fingerprint), 1);
        },
      ).catch((error) => {
        if (__SERVER__) {
          import('modules/logger').then(({ default: logger }) => {
            logger.error('Redux middleware error', error, { label: 'Redux' });
          });
        } else {
          // eslint-disable-next-line no-console
          console.error('MIDDLEWARE ERROR:', error);
        }

        next({ ...rest, error, type: FAILURE });
        next(fail(error));
        activePromises.splice(activePromises.indexOf(fingerprint), 1);
      });

      return actionPromise;
    };
  };
}
