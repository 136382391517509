import React from 'react';

export const defaultValue = {
  loading: false,
  loaded: false,
};

export const getDefaultValue = () => ({
  ...defaultValue,
});

const CurrentPageContext = React.createContext(defaultValue);

CurrentPageContext.displayName = 'CurrentPageContext';

export default CurrentPageContext;
