import { applyMiddleware, createStore, compose } from 'redux';
import createMiddleware from './middleware/clientMiddleware';
import reducer from './reducer';

export default function create(client, data) {
  const middleware = [createMiddleware(client)];

  const composeEnhancers = typeof window === 'undefined'
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle

  const store = createStore(reducer, data, composeEnhancers(
    applyMiddleware(...middleware),
  ));

  return store;
}
