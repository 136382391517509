import superagent from 'superagent';
import { fromRequest as getHostname } from 'shared/hostnames';
import config from '../config';

const methods = ['get', 'post', 'put', 'patch', 'del'];

function formatUrl(path) {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path;
  if (typeof window === 'undefined') {
    // Prepend host and port of the API server to the path.
    return `${config.apiUrl}${adjustedPath}`;
  }
  // Prepend `/api` to relative URL, to proxy to API server.
  return `/api${adjustedPath}`;
}

class ApiClient {
  constructor(req) {
    // Set up methods for all of the REST verbs
    methods.forEach((method) => {
      this[method] = (path, { params, data, modify } = {}) => new Promise((resolve, reject) => {
        const request = superagent[method](formatUrl(path));

        if (params) {
          request.query(params);
        }

        if (typeof window === 'undefined') {
          // If the client has a cookie, forward it on to the API server
          if (req.get('cookie')) {
            request.set('cookie', req.get('cookie'));
          }

          // Forward the hostname so we can do our cool domain matching
          request.set('X-Forwarded-Host', getHostname(req));
        }

        if (data) {
          request.send(data);
        }

        // Exposes direct access to the `request` object
        if (modify) {
          modify(request);
        }

        // Listen for connection errors
        request.on('error', (err) => {
          reject(err);
        });

        request.end((err, { body } = {}) => (err ? reject(body || err) : resolve(body)));
      });
    });

    // Add a special GraphQL convenience endpoint
    this.graphql = ({ query, variables = {}, throwOnError = true }) => new Promise((resolve, reject) => {
      const data = { query, variables };

      this.post('/graphql', { data }).then((result) => {
        if (!throwOnError) {
          resolve(result);
        }

        if (!result) {
          reject(new Error('No result was returned'));
        } else if (result.errors) {
          reject(result.errors);
        } else {
          resolve(result);
        }
      }, (err) => {
        reject(err);
      });
    });
  }
}

export default ApiClient;
