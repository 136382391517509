import React from 'react';
import { connect } from 'react-redux';
import { gql } from 'graphql-tag';
import useQuery from '../../../modules/react/hooks/useQuery';
import useCurrentPage from '../../hooks/useCurrentPage';
import CookieBanner from '../../components/stateful/CookieBanner';
import { PRIVACY_PAGE } from '../../../src/shared/pageTypes';
import { getCompanyName } from '../../../src/web/store/modules/company';

const GET_PRIVACY_PAGE_SLUG = gql`
  query getPrivacyPageSlug($privacyPage: String) {
    pages(filter: {
      live: true,
      type: $privacyPage
    }) {
      edges {
        node {
          slug
        }
      }
    }
  }
`;

const CookieBannerContainer = ({ companyName }) => {
  const { data, loading, error } = useQuery(GET_PRIVACY_PAGE_SLUG,
    { variables: { privacyPage: PRIVACY_PAGE } });

  const { slug } = data?.pages?.edges[0]?.node || {};
  const privacySlug = `/${slug}`;

  const { primaryColorBackgroundClass, secondaryColorBackgroundHoverClass,
    buttonStyleClass, primaryFontFamilyClass } = useCurrentPage();

  if (loading || error) {
    return null;
  }

  return (
    <CookieBanner
      privacyPath={privacySlug}
      companyName={companyName}
      buttonStyleClass={buttonStyleClass}
      primaryFontFamilyClass={primaryFontFamilyClass}
      primaryColorBackgroundClass={primaryColorBackgroundClass}
      secondaryColorBackgroundHoverClass={secondaryColorBackgroundHoverClass}
    />
  );
};

const mapStateToProps = state => ({
  companyName: getCompanyName(state),
});

export default connect(mapStateToProps)(CookieBannerContainer);
