import escapeRegex from 'escape-string-regexp';

/**
 * Determines the hostname based on the request.
 *
 * @param {object} req - the request to check
 * @param {bool} respectProxy - if true, checks the 'X-Forwarded-' header
 * @returns {string} the hostname
 */
export function fromRequest(req, respectProxy = true) {
  let { hostname } = req;

  if (respectProxy) {
    const forwardedHost = req.header('x-forwarded-host');
    if (forwardedHost) {
      hostname = forwardedHost;
    }
  }

  // Some proxies or middleware put the port on the hostname and some
  // don't, let's always remove it to give a consistent result
  hostname = hostname.replace(/(:\d+)$/, '').toLowerCase();

  return hostname;
}

/**
 * Takes a list of hostnames and builds an expression that can be used to match an
 * a single hostname against.
 *
 * @param {(string[]|string)} hostnames - the hostnames to build the expression for
 * @returns {RegExp} the expression
 */
export function matchExpression(hostnames) {
  if (!Array.isArray(hostnames)) {
    hostnames = [hostnames]; // eslint-disable-line no-param-reassign
  }

  // Run the hostnames through a regex sanitizer
  const escapedHostnames = hostnames.map(escapeRegex);

  // We `or` together the possible hostnames and ensure they are proceeded by a
  // period (i.e., the given domain is a subdomain) or the beginning of the string
  return new RegExp(
    '(?:^|\\.)(?:' + escapedHostnames.join('|') + ')$', // eslint-disable-line prefer-template
    'i',
  );
}

const ADMIN_URL = process.env.VOYAGER_ADMIN_HOST;
const internalUrlMatcher = ADMIN_URL && matchExpression(ADMIN_URL);

/**
 *
 * @param {string} hostname - the hostname to check
 * @returns {boolean} whether this hostname is an admin hostname
 */
export function isAdminHostname(hostname) {
  return internalUrlMatcher && internalUrlMatcher.test(hostname);
}
