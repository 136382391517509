
export function query(strings, ...values) {
  const variables = {};
  const queryParts = strings.map((partial, index) => {
    if (index < values.length) {
      const vars = values[index];

      // If this isn't an object, just concat it and move on
      if (typeof vars !== 'object') {
        return `${partial}${vars}`;
      }

      // This variable stitching only works with one variable because it doesn't include the typedef
      const keys = Object.keys(vars);
      if (keys.length > 1) {
        throw new Error('Only one variable can be passed at a time');
      }

      const varNames = keys.map((name) => {
        variables[name] = vars[name];
        return `$${name}`;
      });

      return `${partial}${varNames.join(', ')}`;
    }

    return partial;
  });
  return client => (
    client.graphql({
      query: queryParts.join(''),
      variables,
    })
  );
}
