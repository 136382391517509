import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { getTranslations } from 'store/modules/company';

/* eslint-disable global-require */
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
  require('@formatjs/intl-pluralrules/dist/locale-data/fr');
  require('@formatjs/intl-pluralrules/dist/locale-data/es');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/es');
}
/* eslint-enable global-require */


const mapStateToProps = (state, ownProps) => {
  const { getLocale } = ownProps;
  const locale = getLocale(state);
  const props = { locale };
  const translations = getTranslations(state);
  const enabledTranslations = translations.filter(translation => translation.enabled);
  const translation = enabledTranslations.find(trans => trans.language === locale);
  if (translation) {
    const messages = {};
    translation.tokens.forEach((token) => {
      if (!token.key) return;
      messages[token.key] = token.value || token.defaultValue;
    });
    props.messages = messages;
  }

  return props;
};

const propTypes = {
  locale: PropTypes.string.isRequired,
};

const defaultProps = {};

const ConnectedIntlProvider = props => (
  <IntlProvider textComponent="span" key={props.locale} defaultLocale="en-us" {...props} />
);

ConnectedIntlProvider.propTypes = propTypes;
ConnectedIntlProvider.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
)(ConnectedIntlProvider);
