import { query } from 'utils/graphql';

// Actions
const SHOW_VIEW_MODAL = 'voyager/reviews/SHOW_VIEW_MODAL';
const HIDE_VIEW_MODAL = 'voyager/reviews/HIDE_VIEW_MODAL';
const CHANGE_SORT_ORDER = 'voyager/reviews/CHANGE_SORT_ORDER';
const SHOW_REVIEW_MODAL = 'voyager/reviews/SHOW_REVIEW_MODAL';
const HIDE_REVIEW_MODAL = 'voyager/reviews/HIDE_REVIEW_MODAL';
const SUBMIT = 'voyager/reviews/SUBMIT';
const SUBMIT_SUCCESS = 'voyager/reviews/SUBMIT_SUCCESS';
const SUBMIT_FAIL = 'voyager/reviews/SUBMIT_FAIL';

// Reducer
const initialState = {
  showViewModal: false,
  sortOrder: 'newest',
  showReviewModal: false,
  error: null,
  success: false,
  isSubmitting: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_VIEW_MODAL:
    case HIDE_VIEW_MODAL:
      return {
        ...state,
        showViewModal: action.type === SHOW_VIEW_MODAL,
      };
    case CHANGE_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.sortOrder,
      };
    case SHOW_REVIEW_MODAL:
    case HIDE_REVIEW_MODAL:
      return {
        ...state,
        showViewModal: false,
        review: null,
        success: false,
        showReviewModal: action.type === SHOW_REVIEW_MODAL,
      };
    case SUBMIT:
      return {
        ...state,
        error: null,
        success: false,
        isSubmitting: true,
      };
    case SUBMIT_FAIL:
      return {
        ...state,
        isSubmitting: false,
        error: action.error.errors,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        success: true,
        review: action.review,
      };
    default:
      return state;
  }
}

// Action Creators
export function showViewModal() {
  return {
    type: SHOW_VIEW_MODAL,
  };
}

export function hideViewModal() {
  return {
    type: HIDE_VIEW_MODAL,
  };
}

export function changeSortOrder(sortOrder) {
  return {
    type: CHANGE_SORT_ORDER,
    sortOrder,
  };
}

export function showReviewModal() {
  return {
    type: SHOW_REVIEW_MODAL,
  };
}

export function hideReviewModal() {
  return {
    type: HIDE_REVIEW_MODAL,
  };
}

export function submit(facilityId, data) {
  return {
    types: [SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAIL],
    force: true,
    review: {
      ...data,
      facilityId,
    },
    promise: query`
      mutation RequestContact(
        ${{ facilityId }}: String!
        ${{ data }}: ReviewInput!
      ) {
        reviewFacility(facilityId: $facilityId, review: $data)
      }
    `,
  };
}
