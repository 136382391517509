import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/react-common';
import apolloClient from './apolloClient';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const defaultProps = {};

// For more information, see:
// https://www.apollographql.com/docs/react/api/react-common/#apolloprovider
const ApolloClientProvider = ({ children }) => (
  <ApolloProvider client={apolloClient}>
    {children}
  </ApolloProvider>
);

ApolloClientProvider.propTypes = propTypes;
ApolloClientProvider.defaultProps = defaultProps;

export default ApolloClientProvider;
