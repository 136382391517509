import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

/**
 * A HOC that won't render the given component until it's on the client.
 */
export default (loadingComponent = <div>Loading...</div>) => (Component) => {
  // Disable this in testing
  if (process.env.NODE_ENV === 'test') {
    return Component;
  }

  const name = Component.displayName || Component.name || 'Component';
  class ClientOnlyComponent extends React.Component {
    state = {
      isOnClient: false,
    };

    componentDidMount() {
      this.setState({ isOnClient: true }); // eslint-disable-line react/no-did-mount-set-state
    }

    render() {
      const { isOnClient } = this.state;

      if (!isOnClient) {
        return loadingComponent;
      }

      return <Component {...this.props} />;
    }
  }
  ClientOnlyComponent.displayName = `ClientOnlyComponent(${name})`;
  return hoistNonReactStatic(ClientOnlyComponent, Component);
};
