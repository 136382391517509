import get from 'lodash/get';
import { query } from 'utils/graphql';

// Actions
const LOAD = 'voyager/facilities/LOAD';
const LOAD_SUCCESS = 'voyager/facilities/LOAD_SUCCESS';
const LOAD_FAIL = 'voyager/facilities/LOAD_FAIL';
const UPDATE = 'voyager/facilities/UPDATE';
export const UPDATE_SUCCESS = 'voyager/facilities/UPDATE_SUCCESS';
const UPDATE_FAIL = 'voyager/facilities/UPDATE_FAIL';

// Reducer
const initialState = {
  loading: false,
  loaded: false,
  allFacilities: [],
  info: {},
  facilities: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
    case LOAD_FAIL:
      return {
        ...state,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        allFacilities: action.data.facility
          ? state.allFacilities.concat([action.data.facility])
          : state.allFacilities,
        info: {
          ...state.info,
          [action.id]: {
            ...action.data.facility,
          },
        },
      };
    case UPDATE:
      return {
        ...state,
        saving: true,
        error: null,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        saving: false,
        allFacilities: state.allFacilities.map((facility) => {
          if (facility.id === action.id) {
            return action.data.facility;
          }

          return facility;
        }),
        info: {
          ...state.info,
          [action.data.facility.id]: action.data.facility,
        },
      };
    case UPDATE_FAIL:
      return {
        ...state,
        saving: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}

// Selectors
export const getFacilityById = (state, id) => {
  if (!id) {
    return undefined;
  }

  return state.facilities.allFacilities.find(facility => facility.id === id)
    || state.facilities.info[id];
};

export const getFacilityFieldReplacements = (state, id) => {
  const facility = getFacilityById(state, id);

  if (!facility) {
    return {};
  }

  return {
    ...(facility.displayName ? {
      name: {
        label: 'Facility Name',
        value: facility.displayName,
      },
    } : {}),
    ...(facility.address && facility.address.fullAddress ? {
      address: {
        label: 'Facility Address',
        value: facility.address.fullAddress,
      },
    } : {}),
    ...(facility.phone ? {
      phone: {
        label: 'Facility Phone',
        value: facility.phone,
      },
    } : {}),
    ...(facility.email ? {
      email: {
        label: 'Facility E-mail',
        value: facility.email,
      },
    } : {}),
  };
};

// Action Creators
export function load(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id,
    promise: query`
      query GetFacility(${{ id }}: String!) {
        facility(id: $id) {
          ...FacilityProps
        }
      }

      ${FragmentFacilityProps}
    `,
  };
}

export function update(id, data) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    id,
    force: true,
    promise: query`
      mutation UpdateFacility(${{ id }}: String!, ${{ data }}: FacilityInput!) {
        facility: updateFacility(id: $id, facility: $data) {
          ...FacilityProps
        }
      }

      ${FragmentFacilityProps}
    `,
  };
}

// Global
function findFacilityIdFromState(state) {
  // Determines if we're on an admin page or not
  // Note: I hate this... god I hate this
  const pathname = get(state, 'routing.locationBeforeTransitions.pathname', '');
  const matches = pathname.match(/^\/admin\/page\/edit\/([0-9a-z]+)/i);

  // First, look in the page itself for the ID
  const potentialId = get(
    state,
    matches ? 'pageBuilder.facilityId' : 'page.facilityId',
  );
  if (potentialId) {
    return potentialId;
  }

  const pageId = matches ? matches[1] : get(state, ['routes', 'map', pathname]);
  let facilityId;

  if (pageId) {
    state.pages.pages.some((page) => {
      if (page.id === pageId) {
        facilityId = page.facilityId; // eslint-disable-line prefer-destructuring
        return true;
      }

      return false;
    });
  }

  return facilityId;
}

export function findId(props = {}, state) {
  // Check the props for a facility ID, otherwise defer to the one set in state
  return props.facilityId || findFacilityIdFromState(state);
}

// Lets squirrel away some GraphQL fragments down here
const FragmentAddressProps = `
  fragment AddressProps on Address {
    address1
    address2
    city
    state
    postal
    country
    latitude
    longitude
    fullAddress
  }
`;

const FragmentHoursProps = `
  fragment HoursProps on HoursDef {
    note
    hours {
      day
      open
      close
    }
  }
`;

const FragmentSettingsProps = `
  fragment SettingsProps on FacilitySettings {
    enablePublicDescription
    disableReservations
    showAvailableUnitCount
    showAvailableUnitCountThreshold
    reservationDisclaimer
    reservationConfirmation
    enableMoveIns
    enableManagedRates
    enableUsePromoModifiedPricing
    hidePricingDisplayCall
    showUnavailableUnits
    callActionThreshold
    businessNameOverride
    businessAddressDescription
    tagline
    voyagerOnly
    softwareProvider
    leaseUrl
    enableTieredPricing
  }
`;

const FragmentUnitGroupProps = `
  fragment UnitGroupProps on UnitGroup {
    id
    category
    name
    type
    area
    length
    width
    height
    size
    dueAtMoveIn
    dueAtMoveInWithoutFees
    dueMonthly
    availableUnitsCount
    totalUnitsCount
    price
    isWeekly
    popular
    leadOnly
    discountedPrice
    standardRate
    amenities {
      name
      token
    }
    promoId
    moveInUrl
    reservationUrl
    discountPlans {
      id
      availabilityText
      requirementsText
      availableForAllFacilities
      autoApply
      moveInOnly
      description
      publicDescription
      discountPlanDiscounts {
        id
        discountType
        monthNumber
        amount
      }
      hideFromWebsite
      name
      priority
      promotionType
      turnedOn
      startDate
      endDate
    }
    pricingTypeId
    availablePricingTypes {
      pricingTypeId
      marketRate
      marketRateWeekly
      walkInRate
      walkInRateWeekly
    }
  }
`;

const FragmentUnitSummaryProps = `
  fragment UnitSummaryProps on UnitSummary {
    id
    name
    minPrice
    maxPrice
    minArea
    minSize
    availableUnitsCount
    totalUnitsCount
    siteUnitCategoryId
  }
`;

const FragmentFacilityProps = `
  fragment FacilityProps on Facility {
    id
    companyId
    brandName
    currencySymbol
    defaultImageDeleted
    directPhone
    email
    enabled
    expirationWindow
    facilityType
    googlePlaceId
    hasRentalCenter
    linkOutUrl
    displayName
    pageId
    pageStatus
    pagePaths {
      id
      locale
      path
      metadata {
        linkOut
      }
    }
    phone
    primaryLink
    primaryTarget
    primaryAlt
    primaryImage
    rating
    rentalCenterUrl
    reservationFeeRequired
    applicableFeesEnabled
    storeNumber
    reservationWindow
    tenantPortalUrl
    timezone
    useExpirationWindow
    useReservationWindow
    address {
      ...AddressProps
    }
    amenities {
      enabled
      name
    }
    facilityAmenities {
      name
      enabled
    }
    hours {
      office {
        ...HoursProps
      }
      access {
        ...HoursProps
      }
    }
    reviews {
      id
      body
      name
      rating
      timestamp
      reply {
        body
        timestamp
      }
    }
    settings {
      ...SettingsProps
    }
    unitGroups {
      ...UnitGroupProps
    }
    unitSummary {
      ...UnitSummaryProps
    }
    discountPlans {
      id
      availabilityText
      requirementsText
      availableForAllFacilities
      autoApply
      description
      publicDescription
      discountPlanDiscounts {
        id
        discountType
        monthNumber
        amount
      }
      hideFromWebsite
      name
      priority
      promotionType
      turnedOn
      startDate
      endDate
    }
  }

  ${FragmentAddressProps}
  ${FragmentHoursProps}
  ${FragmentSettingsProps}
  ${FragmentUnitGroupProps}
  ${FragmentUnitSummaryProps}
`;
