import pathToRegexp from 'path-to-regexp';
import { query } from 'utils/graphql';

// Actions
const LOAD = 'voyager/routes/LOAD';
export const LOAD_SUCCESS = 'voyager/routes/LOAD_SUCCESS';
const LOAD_FAIL = 'voyager/routes/LOAD_FAIL';

// Reducer
const initialState = {
  loaded: false,
};

// Converts the routes array into a map to quickly look up page details
const pathMap = function (routes) {
  const map = {};
  routes.forEach(({ id, slug }) => {
    map[slug] = id;
  });
  return map;
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        routes: action.data.routes,
        map: pathMap(action.data.routes),
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

// Selectors
export function isLoaded(state) {
  return state.routes && state.routes.loaded;
}

export function id(state, pagePath) {
  const route = state.routes.routes.find(({ path }) => {
    const regexp = pathToRegexp(path, {}, { end: true });

    return regexp.test(pagePath);
  });

  if (!route) {
    return null;
  }

  return route.id;
}

export const getRoutes = state => (
  state.routes.routes
);

export const getRouteBySlug = (state, slug, language) => {
  const { routes } = state;
  const arr = routes.routes || [];

  return arr.find(route => (
    language
      ? route.slug === slug && route.locale === language
      : route.slug === slug
  ));
};

export const getRoutePath = (state, slug, language) => {
  const route = getRouteBySlug(state, slug, language);
  return route && route.path;
};

// Action Creators
export function load(force = false) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    force,
    promise: query`
      {
        routes {
          id
          slug
          facilityId
          type
          locale
          path
        }
      }
    `,
  };
}
