import { query } from 'utils/graphql';

// Actions
const CREATE = 'voyager/lead/CREATE';
const CREATE_SUCCESS = 'voyager/lead/CREATE_SUCCESS';
const CREATE_FAIL = 'voyager/lead/CREATE_FAIL';
const CREATE_SOURCE = 'voyager/lead/CREATE_SOURCE';
const CREATE_SOURCE_SUCCESS = 'voyager/lead/CREATE_SOURCE_SUCCESS';
const CREATE_SOURCE_FAIL = 'voyager/lead/CREATE_SOURCE_FAIL';
const RESET = 'voyager/lead/RESET';

// Reducer
const initialState = {
  creating: false,
  created: false,
  errorMessage: '',
  creatingSource: false,
  createdSource: false,
  sourceErrorMessage: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE:
      return {
        ...state,
        creating: true,
        created: false,
        errorMessage: '',
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        created: true,
      };
    case CREATE_FAIL:
      return {
        ...state,
        creating: false,
        created: false,
        errorMessage: action.error.response.body.errors[0].message,
      };
    case CREATE_SOURCE:
      return {
        ...state,
        creatingSource: true,
        createdSource: false,
        sourceErrorMessage: '',
      };
    case CREATE_SOURCE_SUCCESS:
      return {
        ...state,
        creatingSource: false,
        createdSource: true,
      };
    case CREATE_SOURCE_FAIL:
      return {
        ...state,
        creatingSource: false,
        createdSource: false,
        sourceErrorMessage: action.error.response.body.errors[0].message,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function create(facilityId, source, tenant, isGated, curious, notes) {
  return {
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    force: true,
    /* eslint-disable max-len */
    promise: query`
      mutation CreatePopUpLead(
        ${{ facilityId }}: String!
        ${{ source }}: String!
        ${{ tenant }}: LeadTenantInput!
        ${{ isGated }}: Boolean
        ${{ curious }}: Boolean
        ${{ notes }}: String
      ) {
        tenant: createPopUpLead(
          facilityId: $facilityId,
          source: $source,
          tenant: $tenant,
          isGated: $isGated,
          curious: $curious,
          notes: $notes
        ) {
          id
          firstName
          lastName
        }
      }
    `,
  };
}
/* eslint-enable max-len */

export function createLeadSource(name) {
  return {
    types: [CREATE_SOURCE, CREATE_SOURCE_SUCCESS, CREATE_SOURCE_FAIL],
    promise: query`
      mutation CreateCustomLeadSource(
        ${{ name }}: String!
      ) {
        leadSource: createCustomLeadSource(name: $name) {
          name
        }
      }
    `,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}
