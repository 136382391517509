import { connect } from 'react-redux';
import { getPageFacility } from 'store/modules/page';
import { getSite } from 'store/modules/site';
import CurrentPageClientProvider from 'website/components/stateless/CurrentPageClientProvider';

const mapStateToProps = state => ({
  currentSite: getSite(state),
  facility: getPageFacility(state),
});

export default connect(
  mapStateToProps,
)(CurrentPageClientProvider);
