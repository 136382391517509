import React from 'react';
import authorization from '../../modules/authorization';

export const defaultValue = {
  ...authorization(),
};

export const getDefaultValue = () => ({
  ...defaultValue,
});

const CurrentUserContext = React.createContext(defaultValue);

CurrentUserContext.displayName = 'CurrentUserContext';

export default CurrentUserContext;
