import { DEFAULT_LOCALE } from 'shared/i18n';
import { LOAD_APP, LOAD_APP_FAIL, LOAD_APP_SUCCESS } from './app';

// Actions

// Reducer
const initialState = {
  loading: false,
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_APP:
      return {
        ...state,
        loading: true,
      };
    case LOAD_APP_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: action.error,
      };
    case LOAD_APP_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        ...action.data.company,
      };
    default:
      return state;
  }
}

// Helpers
const generateFieldReplacement = (label, value) => {
  if (!value) {
    return {};
  }

  return { label, value };
};

// Selectors
export const getCompany = state => state.company;

export const getCompanyId = state => state.company.id;

export const getCompanyName = ({ site: { customCompanyName, customCompanyNameEnabled }, company: { name } }) => (
  customCompanyNameEnabled ? customCompanyName : name
);

export const getCompanyAddress = state => state.company.address;

export const getCompanySettingsUrl = state => state.company.companySettingsUrl;

export const getCorporateUrl = state => state.company.corporateUrl;

export const getStorEdgeUrl = state => state.company.storEdgeUrl;

export const getLeadSources = state => state.company.sources;

export const getCompanyFieldReplacements = (state) => {
  if (!state.company) {
    return {};
  }

  const {
    company: { name, address, email, phone }, site: { customCompanyName, customCompanyNameEnabled },
  } = state;

  const companyName = customCompanyNameEnabled ? customCompanyName : name;

  return [
    generateFieldReplacement('Company Name', companyName),
    generateFieldReplacement('Company Address', address && address.fullAddress),
    generateFieldReplacement('Company E-mail', email),
    generateFieldReplacement('Company Phone', phone),
  ];
};

export const getFlippers = state => state.company.flippers;

export const getFlipper = (state, flipper) =>
  state.company.flippers && state.company.flippers[flipper];

export const getLocales = state => state.company.locales;

export const getNonDefaultLocales = state => getLocales(state)
  .filter(locale => locale.code !== DEFAULT_LOCALE);

export const getLocaleCodes = state => getLocales(state)
  .map(locale => locale.code);

export const getNonDefaultLocaleCodes = state => getNonDefaultLocales(state)
  .map(locale => locale.code);

export const getTranslations = state => state.company.translations;

// Action Creators

// Global
export function isLoaded(globalState) {
  return globalState.company && globalState.company.loaded;
}
