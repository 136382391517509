import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useDispatch, useSelector } from 'react-redux';
import hydratable from '../../../hocs/hydratable';
import { ID } from './properties';
import useMutation from '../../../../modules/react/hooks/useMutation';
import useEventTrigger from '../../../../modules/react/hooks/useEventTrigger';
import { clearEvents } from '../../../../src/web/store/modules/websiteEvents';
import { TRACK_PAGE_VIEW } from '../../../../src/web/utils/dataLayer';

const SEND_EVENT_DATA_LAKE = gql`
mutation CreateWebsiteEvent ($events: [JSON!]!) {
  websiteEvents(events: $events) {
    userErrors {
      message
    }
  }
}
`;

const propTypes = {
  event: PropTypes.object,
  interval: PropTypes.number,
};

const defaultProps = {
  event: {},
  interval: 2000,
};

const PageViewTrigger = ({
  event,
  interval,
}) => {
  const { addEvent } = useEventTrigger();
  const [sendEvent] = useMutation(SEND_EVENT_DATA_LAKE);
  const currentEvents = useSelector(state => state?.websiteEvents?.currentEvents);
  const intervalRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    addEvent(TRACK_PAGE_VIEW, event, 'view');
  }, []);

  // Function to send batched events
  const sendBatchedEvents = async () => {
    if (currentEvents.length === 0) { return; }
    try {
      sendEvent({ variables: { events: currentEvents } });
      dispatch(clearEvents()); // Clear the queue after sending
    } catch (err) {
      // console.log('Batched events error:', err);
    }
  };

  // Set up interval to send batched events
  useEffect(() => {
    intervalRef.current = setInterval(sendBatchedEvents, interval);
    return () => {
      clearInterval(intervalRef.current); // Clear interval on cleanup
    };
  }, [currentEvents, interval]);
  return null;
};

PageViewTrigger.propTypes = propTypes;
PageViewTrigger.defaultProps = defaultProps;

export default hydratable(PageViewTrigger, ID);
