
/**
 * This guy allows us to compose functions together to make HOC composition less
 * annoying to read.
 *
 * E.g., normally using connect you'd write something like:
 * <code>
 *   const HOC = connect(mapStateToProps)(MyComponent);
 * </code>
 *
 * As you might notice, this gets ridiculously unwieldy if you need to wrap a component
 * multiple times:
 * <code>
 *   const HOC = connect(mapStateToProps)(dragSource(mapper, source, collect)(pure()(MyComponent)));
 * </code>
 *
 * This function will recursively apply the constructed arguments over the final argument:
 * <code>
 *   const HOC = compose(
 *     connect(mapStateToProps),
 *     dragSource(mapper, source, collect),
 *     pure()
 *   )(MyComponent);
 * </code>
 *
 * NOTE: As a hack to make testing easier, this function DOES NOT compose functions when `NODE_ENV=test`!
 */
const compose = (fn, ...rest) => {
  if (process.env.NODE_ENV === 'testing') {
    if (process.env.TEST_ENV === 'unit') {
      if (!fn.$$voyagerTestWhitelist) {
        fn = arg => arg; // eslint-disable-line no-param-reassign
      }
    } else {
      return arg => arg;
    }
  }

  if (rest.length === 0) {
    return fn;
  }

  return (...args) => fn(compose(...rest)(...args));
};

export default compose;
